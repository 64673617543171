import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import SectionContainer from "./ui/SectionContainer";
import { colors } from "../styles/colors";
import yaiglobalusa from "../images/yaiglobal_usa.png";
import yaiglobaltun from "../images/yaiglobal_tun.png";

export function About() {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6, ease: "easeOut" },
  };

  const staggerContainer = {
    animate: {
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    initial: { opacity: 0, scale: 0.8 },
    animate: { 
      opacity: 1, 
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const headerVariants = {
    initial: { opacity: 0, y: -20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.7,
        ease: "easeOut"
      }
    }
  };

  const imageVariants = {
    initial: { opacity: 0, scale: 0.95 },
    animate: { 
      opacity: 1, 
      scale: 1,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  return (
    <>
      {/* Helmet SEO Tags */}
      <Helmet>
        <title>About YaiGlobal - AI-Powered Document Solutions</title>
        <meta
          name="description"
          content="Learn more about YaiGlobal, an AI-driven company specializing in NLP, document digitization, and intelligent automation. Meet our founders and discover our offices."
        />
        <meta property="og:title" content="About YaiGlobal - AI-Powered Document Solutions" />
        <meta
          property="og:description"
          content="YaiGlobal is revolutionizing AI-driven document processing with cutting-edge NLP solutions. Meet our leadership team and learn about our global presence."
        />
        <meta property="og:image" content="%PUBLIC_URL%/images/logo.png" />
        <meta property="og:url" content="https://yaiglobal.com/" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://yaiglobal.com/" />
      </Helmet>

      <div className="bg-gray-900 text-white py-12">
        <SectionContainer>
          <div className="space-y-24">
            <motion.div 
              initial="initial" 
              whileInView="animate" 
              viewport={{ once: true }}
              variants={fadeInUp}
            >
              <motion.h2 
                variants={headerVariants}
                className="text-center mb-4 text-4xl font-bold text-gray-900 md:text-5xl lg:text-7xl"
              >
                <span style={{ color: "white"}}>About </span>
                <span style={{ color: colors.primary }}>Y</span>
                <span style={{ color: colors.secondary }}>ai</span>
                <span style={{ color: colors.primary }}>Global</span>
              </motion.h2>
              <motion.p 
                variants={fadeInUp}
                className="text-center mx-auto max-w-3xl mt-4 text-xl text-gray-400"
              >
                Pioneering intelligent document solutions through advanced NLP, transforming how businesses digitize, translate, and analyze information.
              </motion.p>
            </motion.div>

            <motion.div 
              variants={staggerContainer}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
              className="space-y-8"
            >
              <div className="grid md:grid-cols-3 gap-6">
                {[
                  "Cutting-edge AI technology",
                  "Expert team of data scientists",
                  "Customized solutions for your needs",
                  "Time and cost savings",
                  "Secure and confidential processing",
                  "Seamless integration"
                ].map((service, index) => (
                  <motion.div
                    key={index}
                    variants={cardVariants}
                    whileHover={{ scale: 1.05 }}
                    className="p-10 bg-gray-800 rounded-3xl hover:bg-gray-700 transition-all duration-300 shadow-2xl text-center text-2xl font-semibold text-gray-300 hover:text-white flex items-center justify-center min-h-[200px]"
                  >
                    {service}
                  </motion.div>
                ))}
              </div>
            </motion.div>

            <motion.div 
              variants={staggerContainer}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
              className="space-y-8"
            >
              <motion.h2 
                variants={headerVariants}
                className="text-center mb-4 text-4xl font-bold text-gray-900 md:text-5xl lg:text-4xl"
              >
                <span style={{ color: "white"}}>Our </span>
                <span style={{ color: colors.secondary }}>Founders</span>
              </motion.h2>

              <div className="grid md:grid-cols-2 gap-8">
                {[{
                  name: "Dr. Mokhtar Sadok",
                  description:
                    "Dr. Sadok has 25+ years of industrial experience in aerospace, robotics, and NLP. With 15 patents and dozens of scientific papers, he brings an R&D spirit to YaiGlobal, inventing new ways of processing data and serving customers.",
                }, {
                  name: "Mr. Mourad Othman",
                  description:
                    "Mr. Othman has 20+ years of experience in business operations and delivering service projects. Having scaled a German startup to a mid-size international enterprise, he brings expertise in leadership and sustainable growth to YaiGlobal.",
                }].map((founder, index) => (
                  <motion.div
                    key={index}
                    variants={cardVariants}
                    whileHover={{ y: -10 }}
                    className="rounded-3xl bg-gray-800 p-12 shadow-2xl hover:shadow-3xl transition-all duration-300 space-y-6"
                  >
                    <h4 className="text-4xl font-bold text-white">{founder.name}</h4>
                    <p className="text-gray-300 leading-relaxed text-xl">{founder.description}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            <motion.div 
              variants={staggerContainer}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
              className="space-y-8"
            >
              <motion.h2 
                variants={headerVariants}
                className="text-center mb-4 text-4xl font-bold text-gray-900 md:text-5xl lg:text-4xl"
              >
                <span style={{ color: "white"}}>Our </span>
                <span style={{ color: colors.secondary }}>Offices</span>
              </motion.h2>
              <div className="grid md:grid-cols-2 gap-8">
                {[{ location: "5201 Great America Pkwy, Suite 320 Santa Clara, CA 95054, United States", image: yaiglobalusa }, { location: "POLE TECHNOLOGIQUE El Ghazela Bloc E2 – Raoued, Ariana 2088, BP 112", image: yaiglobaltun }].map((office, index) => (
                  <motion.div 
                    key={index} 
                    variants={imageVariants}
                    whileHover={{ scale: 1.02 }}
                    className="relative overflow-hidden rounded-3xl shadow-2xl group"
                  >
                    <img
                      src={office.image}
                      alt={office.location}
                      className="w-full h-96 object-cover transform transition-transform duration-500 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/30 to-transparent flex items-end">
                      <p className="w-full text-center text-lg font-medium text-white py-6">
                        {office.location}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        </SectionContainer>
      </div>
    </>
  );
}

export default About;
