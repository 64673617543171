import React, { useEffect, useState, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import SectionContainer from "./ui/SectionContainer";
import SectionHeader from "./ui/SectionHeader";
import awslogo from "../images/awslogo.png";
import piterionlogo from "../images/piterionlogo.png";
import strategytClogo from "../images/strategytClogo.png";
import appteklogo from "../images/appteklogo.png";
import columbialogo from "../images/columbialogo.png";

const PartnersScroller = () => {
  const [width, setWidth] = useState(0);
  const controls = useAnimation();
  const scrollRef = useRef(null);

  // Array of partner logos with size classes
  const partners = [
    { logo: awslogo, sizeClass: "h-20 w-auto" },  // 80px height
    { logo: piterionlogo, sizeClass: "h-60 w-auto" }, // 96px height
    { logo: strategytClogo, sizeClass: "h-20 w-auto" }, // 80px height
    { logo: appteklogo, sizeClass: "h-16 w-auto" }, // 64px height
    { logo: columbialogo, sizeClass: "h-28 w-auto" }, // 112px height
  ];

  useEffect(() => {
    if (!scrollRef.current) return;

    const calculateWidth = () => {
      const container = scrollRef.current;
      if (container) {
        const singleSetWidth = Array.from(container.children[0].children)
          .slice(0, partners.length)
          .reduce((total, element) => {
            const style = window.getComputedStyle(element);
            return total + element.offsetWidth + parseInt(style.marginRight);
          }, 0);
        
        setWidth(singleSetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(calculateWidth);
    resizeObserver.observe(scrollRef.current);
    calculateWidth();

    return () => resizeObserver.disconnect();
  }, [partners.length]);

  useEffect(() => {
    if (!width) return;

    const startAnimation = async () => {
      await controls.start({
        x: -width,
        transition: {
          duration: 7,
          ease: "linear",
          repeat: Infinity,
          repeatType: "loop",
          repeatDelay: 0
        }
      });
    };

    startAnimation();
    return () => controls.stop();
  }, [controls, width]);

  return (
    

      
      <div className="relative overflow-hidden ">
        <div className="absolute left-0 top-0 h-full w-24 " />
        <div className="absolute right-0 top-0 h-full w-24 " />
        
        <div 
          className="scroll-container relative overflow-hidden"
          ref={scrollRef}
        >
          <motion.div
            className="flex items-center space-x-16 py-8"
            animate={controls}
            style={{ willChange: 'transform' }}
          >
            {partners.map((partner, index) => (
              <div
                key={`first-${index}`}
                className="flex-shrink-0 transition-transform duration-300"
              >
                <div className="flex items-center justify-center">
                  <img
                    src={partner.logo}
                    alt={`Partner ${index + 1}`}
                    className={`object-contain ${partner.sizeClass}`}
                  />
                </div>
              </div>
            ))}
            {partners.map((partner, index) => (
              <div
                key={`second-${index}`}
                className="flex-shrink-0 transition-transform duration-300"
              >
                <div className="flex items-center justify-center">
                  <img
                    src={partner.logo}
                    alt={`Partner ${index + 1}`}
                    className={`object-contain ${partner.sizeClass}`}
                  />
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
  );
};

export default PartnersScroller;