import React from "react"
import { motion } from "framer-motion"
import { colors } from "../../styles/colors"
import PropTypes from "prop-types";

export function SectionHeader({ title, subtitle }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
      className="mb-16 text-center"
    >
      <h2 className="mb-4 text-4xl font-bold text-gray-900 md:text-5xl lg:text-6xl">
        {title.split(" ").map((word, index) => (
          <span
            key={index}
            className={index % 2 === 0 ? "text-gray-900" : ""}
            style={{ color: index % 2 !== 0 ? colors.primary : undefined }}
          >
            {word}{"   "}
          </span>
        ))}
      </h2>
      <p className="mx-auto max-w-2xl text-xl text-gray-600">{subtitle}</p>
    </motion.div>
  )
}

export default SectionHeader;

SectionHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};
