import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom"; 
import SectionContainer from "./ui/SectionContainer";
import SectionHeader from "./ui/SectionHeader";
import { colors } from "../styles/colors";
import yai4eduEeditor from "../images/yai4eduEeditor.png";
import Yai365bench from "../images/Yai365bench.png";
import Yai4health from "../images/Yai4health.png";

const productRoutes = {
  Yai4Edu: "/products/yai4edu",
  "Yai365": "/products/yai365",
  Yai4Health: "/products/yai4health",
};

const productWebsites = {
  Yai4Edu: "https://yai4edu.yai365.com/",
  Yai365: "http://workbench.yai365.com/",
  YAi4Health: "https://yai4health.yai365.com",
};

const products = [
  {
    name: "Yai4Edu",
    description:
      "Yai4Edu streamlines library digitization with AI-driven features like summarization, translation, text-to-speech, full-text search, and bookmarking, enhancing accessibility and research efficiency.",
    image: yai4eduEeditor,
  },
  {
    name: "Yai365",
    description:
      "Yai365 Workbench is a leading annotation platform for high-quality data labeling across images, audio, video, text, and PDFs. With intuitive workflow management and advanced AI tools, it enables seamless annotation for projects of any scale, ensuring precision and efficiency in AI model development.",
    image: Yai365bench,
  },
  {
    name: "Yai4Health",
    description:
      "Yai4Health enhances medical record management with AI-driven bookmarking for quick access to key information and an intelligent Medical Agent for accurate, patient-specific insights, revolutionizing healthcare efficiency.",
    image: Yai4health,
  },
];

export default function OurProducts() {
  const navigate = useNavigate();

  return (
    <>
      {/* Helmet SEO Tags */}
      <Helmet>
        <title>Our AI Products - YAiGlobal</title>
        <meta
          name="description"
          content="Explore YaiGlobal's AI-powered products including YAi4Edu, YAi365 Workbench, and YAi4Health. Discover how our AI solutions transform document processing and healthcare management."
        />
        <meta property="og:title" content="Our AI Products - YAiGlobal" />
        <meta
          property="og:description"
          content="Discover YAi4Edu for library digitization, YAi365 Workbench for annotation, and YAi4Health for medical AI solutions."
        />
        <meta property="og:image" content="%PUBLIC_URL%/images/products-overview.jpg" />
        <meta property="og:url" content="https://www.yaiglobal.com/products" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.yaiglobal.com/products" />
      </Helmet>

      <SectionContainer className="bg-gradient-to-b from-gray-50 to-white py-20">
        <SectionHeader
          title="Our Innovative Products"
          subtitle="Discover our cutting-edge AI solutions designed to transform your document processing"
        />

        <div className="space-y-24 max-w-8xl mx-auto">
          {products.map((product, index) => (
            <motion.div
              key={product.name}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              className={`flex flex-col items-center gap-12 md:flex-row ${
                index % 2 === 0 ? "md:flex-row-reverse" : ""
              }`}
            >
              {/* Product Image */}
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                className="w-full md:w-1/2"
              >
                <img
                  src={product.image}
                  alt={`${product.name} screenshot`}
                  className="w-full rounded-lg shadow-lg transition-all duration-300"
                />
              </motion.div>

              {/* Product Content */}
              <div className="w-full md:w-1/2 space-y-6">
                <h3 className="text-3xl font-bold text-gray-900">{product.name}</h3>
                <p className="text-lg text-gray-600 leading-relaxed">
                  {product.description}
                </p>

                {/* CTA Buttons */}
                <div className="flex flex-col sm:flex-row gap-4">
                  {/* Learn More Button */}
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="px-6 py-3 rounded-lg bg-blue-600 text-white font-medium shadow-md hover:bg-blue-700 transition-all duration-300"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(productRoutes[product.name]);
                    }}
                    style={{ backgroundColor: colors.primary }}
                  >
                    Learn More
                  </motion.button>

                  {/* Try Now Button - Only for YAi4Edu */}
                  {product.name === "Yai4Edu" && (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="px-6 py-3 rounded-lg border border-blue-600 text-blue-600 font-medium shadow-md hover:bg-blue-50 transition-all duration-300"
                      onClick={() => window.open(productWebsites[product.name], "_blank")} 
                      style={{ color: colors.primary, borderColor: colors.primary }}
                    >
                      Try Now
                    </motion.button>
                  )}
                </div>
                
              </div>
            </motion.div>
            
          ))}
        </div>

      </SectionContainer>
    </>
  );
}
