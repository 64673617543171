import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import { BookOpen, Bot, Bookmark } from "lucide-react";
import SectionContainer from "../../components/ui/SectionContainer";
import SectionHeader from "../../components/ui/SectionHeader";
import patientchat from "./images/patientchat.png";
import Yai4health from "./images/Yai4health.png";
import { useNavigate } from "react-router-dom"; 

const features = [
  {
    icon: BookOpen,
    title: "Medical Records Bookmarking System",
    description: "Ensures quick and efficient access to key data.",
  },
  {
    icon: Bot,
    title: "Medical Agent (AI Assistant)",
    description: "Answers medical inquiries and provides patient-specific insights using AI.",
  },
  {
    icon: Bookmark,
    title: "Smart Bookmarking",
    description: "Enables users to bookmark and organize medical content efficiently.",
  },
];

const showcaseItems = [
  {
    image: Yai4health,
    title: "Medical Records Bookmarking System",
    description:
      "Yai4health's AI-driven system organizes medical data, making it easily accessible for healthcare professionals, improving efficiency and patient care.",
  },
  {
    image: patientchat,
    title: "AI-Powered Medical Agent",
    description:
      "The Medical Agent is an AI assistant that analyzes medical records, providing intelligent insights and supporting clinical decision-making.",
  }
];

const Yai4healthPage = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Helmet SEO Metadata */}
      <Helmet>
        <title>YAi4Health - AI-Powered Medical Record Management</title>
        <meta
          name="description"
          content="Yai4Health is an AI-powered platform revolutionizing medical record management with smart bookmarking and an AI medical assistant for patient insights."
        />
        <meta property="og:title" content="YAi4Health - AI-Powered Medical Record Management" />
        <meta
          property="og:description"
          content="Enhance healthcare efficiency with YAi4Health's intelligent medical bookmarking and AI-powered medical assistant."
        />
        <meta property="og:image" content="%PUBLIC_URL%/images/Yai4health_bookmarking.png" />
        <meta property="og:url" content="https://yaiglobal.com/products/yai4health" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://yaiglobal.com/products/yai4health" />
      </Helmet>

      <main className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <SectionContainer>
          {/* Section Header */}
          <SectionHeader
            title="Yai4Health: Transforming Healthcare with AI"
            subtitle="Smarter Medical Records for Better Care."
          />

          {/* Intro Text */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mb-12 text-lg text-gray-700 leading-relaxed"
          >
            <p className="mb-4">
              Yai4Health is an AI-powered platform designed to optimize medical record management and streamline healthcare workflows.
              It features:
            </p>
            <ul className="list-disc ml-6">
              <li>AI-driven Medical Records Bookmarking System for efficient data organization.</li>
              <li>Medical AI Assistant for patient-specific insights and clinical support.</li>
              <li>Enhanced workflow automation for healthcare professionals.</li>
            </ul>
          </motion.div>

          {/* Features Grid */}
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 mb-16">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white p-6 rounded-lg shadow-md"
              >
                <feature.icon className="h-12 w-12 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>

          {/* Showcase Items */}
          <div className="space-y-0">
            {showcaseItems.map((item, index) => (
              <motion.div
                key={item.title}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className={`flex flex-col items-center gap-8 md:flex-row ${
                  index % 2 === 0 ? "" : "md:flex-row-reverse"
                }`}
              >
                <div className="w-full md:w-1/2">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="rounded-lg shadow-lg object-cover w-full"
                    style={item.image === patientchat ? { transform: "scale(0.6)", transformOrigin: "center" } : {}}
                  />
                </div>
                <div className="w-full md:w-1/2 space-y-4">
                  <h3 className="text-2xl font-bold text-gray-900">{item.title}</h3>
                  <p className="text-lg text-gray-600">{item.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="flex justify-center ">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate("/contact")}
              className=" px-6 py-3 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
            >
              Book a demo
            </motion.button>
          </div>
        </SectionContainer>
      </main>
    </>
  );
};

export default Yai4healthPage;
