// src/components/ui/Button.js
import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types";

// A minimal Tailwind-based button component.
// You can expand this to handle `variant`, `size`, etc. as you like.
export function Button({
  children,
  className = "",
  style = {},
  onClick,
  variant = "default",
  size = "md",
  ...props
}) {
  // Basic style for the default variant
  let baseClasses =
    "inline-flex items-center justify-center rounded bg-gray-800 px-4 py-2 text-white transition-colors"

  // Simple variant styling
  if (variant === "outline") {
    baseClasses =
      "inline-flex items-center justify-center rounded border px-4 py-2 transition-colors"
  } else if (variant === "ghost") {
    baseClasses =
      "inline-flex items-center justify-center rounded px-4 py-2 transition-colors"
  }

  // Simple size styling
  if (size === "lg") {
    baseClasses += " text-lg"
  } else if (size === "icon") {
    baseClasses += " p-2"
  }

  return (
    <button
      onClick={onClick}
      style={style}
      className={clsx(baseClasses, className)}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button;

Button.propTypes = {
  children: PropTypes.node, 
  className: PropTypes.string,
  style: PropTypes.object, 
  onClick: PropTypes.func,
  variant: PropTypes.string,
  size: PropTypes.string,
};