// src/components/CTA.js
import React from "react"
import { motion } from "framer-motion"
import SectionContainer from "./ui/SectionContainer"
import { Button } from "./ui/Button"
import { colors } from "../styles/colors"
import { useNavigate } from "react-router-dom" 

export function CTA() {
  const navigate = useNavigate()

  return (
    <SectionContainer className="bg-gray-900 text-white">
      <div className="text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="mb-6 text-4xl font-bold md:text-5xl lg:text-6xl">
            Ready to <span style={{ color: colors.secondary }}>Transform</span> Your Documents?
          </h2>
          <p className="mx-auto mb-8 max-w-2xl text-xl text-gray-300">
            Start digitizing your documents today and unlock valuable insights with our NLP services.
          </p>
          <div className="flex flex-col items-center justify-center gap-4 sm:flex-row">
            <Button size="lg" style={{ backgroundColor: colors.primary }} className="text-lg hover:opacity-90"  
              onClick={() => navigate("/digitize-article")}>
              Get Started
            </Button>
          </div>
        </motion.div>
      </div>
    </SectionContainer>
  )
}

export default CTA
