import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import SectionContainer from "./ui/SectionContainer";
import { colors } from "../styles/colors";
import Partners from './Partners';
import { useNavigate } from "react-router-dom"; 

export default function Hero() {
  const stars = Array.from({ length: 50 });
  const navigate = useNavigate();

  const handleScrollToContact = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {/* Helmet SEO Tags */}
      <Helmet>
        <title>YaiGlobal - AI-Powered Innovations in Document Processing</title>
        <meta 
          name="description" 
          content="Transforming industries with AI-driven solutions in NLP, document digitization, and smart bookmarking. Unlock knowledge with YaiGlobal." 
        />
        <meta property="og:title" content="YaiGlobal - AI-Powered Innovations in Document Processing" />
        <meta property="og:description" content="We provide AI-driven solutions for document processing, NLP, OCR, and multilingual translation." />
        <meta property="og:image" content="%PUBLIC_URL%/images/logo.png" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yaiglobal.com" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://yaiglobal.com/" />
      </Helmet>

      {/* Hero Section */}
      <div className="relative bg-gray-900 min-h-[70vh] flex flex-col justify-center overflow-hidden">
        
        {/* Star Animation */}
        {stars.map((_, index) => {
          const randomDuration = Math.random() * 10 + 5; // 5s to 15s
          const randomSize = Math.random() * 3 + 1; // 1px to 4px
          return (
            <motion.div
              key={index}
              className="absolute bg-white rounded-full"
              style={{
                width: `${randomSize}px`,
                height: `${randomSize}px`,
                top: `${Math.random() * 100}vh`,
                left: `-${randomSize * 2}px`
              }}
              animate={{
                x: ["0vw", "100vw"],
                opacity: [0.3, 1, 0.3]
              }}
              transition={{
                duration: randomDuration,
                repeat: Infinity,
                ease: "linear",
                delay: Math.random() * 5
              }}
            />
          );
        })}

        <SectionContainer className="py-16">
          {/* Hero Content */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 mt-20">
              Transforming Industries with AI <br />
              Unlocking Knowledge and  <span className="text-teal-400" style={{ color: colors.secondary }}>Innovation</span>
            </h1>
            <p className="text-gray-400 text-xl max-w-2xl mx-auto mb-10">
            Leading projects in the field of Artificial Intelligence since 2018. Successfully executed complex projects involving different industrial sectors including Education and Healthcare.             </p>
            <div className="flex gap-4 justify-center mt-6">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-teal-400 text-white px-6 py-2 rounded-md font-medium border-2"
                style={{ backgroundColor: 'transparent', borderColor: colors.primary }}
                onClick={handleScrollToContact}
              >
                Book a Demo →
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-teal-400 text-white px-6 py-2 rounded-md font-medium border-2"
                style={{ backgroundColor: 'transparent', borderColor: colors.secondary }}
                onClick={() => navigate("/products/yai4edu")} 
              >
                Yai4Edu Free Trial →
              </motion.button>
            </div>
          </motion.div>

          <Partners/>

        </SectionContainer>
      </div>
    </>
  );
}
