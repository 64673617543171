import React from "react";
import { Helmet } from "react-helmet-async";
import { BookOpen, Languages, FileText, Bookmark, Type, HelpCircle } from "lucide-react";
import { motion } from "framer-motion";
import { colors } from "../styles/colors";
import SectionContainer from "./ui/SectionContainer";
import SectionHeader from "./ui/SectionHeader";

const services = [
  {
    icon: BookOpen,
    title: "Advanced OCR",
    description: "Highly accurate text extraction from printed documents, historical manuscripts, and audio data.",
  },
  {
    icon: Languages,
    title: "Multilingual Translation",
    description: "Professional translation supporting 10+ languages, including Turkish Ottoman and Arabic. Other languages are continuously added.",
  },
  {
    icon: FileText,
    title: "AI-Powered Data Analytics",
    description: "Intelligent analysis of documents, medical records, and multimedia content.",
  },
  {
    icon: Bookmark,
    title: "Smart Bookmarking",
    description: "Automated bookmarking for better document organization and navigation augmented by word for word search of the original document.",
  },
  {
    icon: Type,
    title: "Intelligent Search",
    description: "Powerful search functionality for accurate content discovery within historical, handwritten, and multimedia documents.",
  },
  {
    icon: HelpCircle,
    title: "Medical AI Assistant",
    description: "Advanced medical agent capable of answering general inquiries and patient-specific questions with accurate insights.",
  },
];

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

export function Services() {
  return (
    <>
      {/* Helmet SEO Tags */}
      <Helmet>
        <title>AI-Powered NLP & Document Processing Services | YaiGlobal</title>
        <meta
          name="description"
          content="Explore YaiGlobal's AI-driven services: OCR, multilingual translation, AI-powered analysis, smart bookmarking, intelligent search, and a medical AI assistant."
        />
        <meta property="og:title" content="AI-Powered NLP & Document Processing Services | YaiGlobal" />
        <meta
          property="og:description"
          content="YaiGlobal offers advanced AI solutions for document digitization, translation, intelligent search, and medical AI assistance."
        />
        <meta property="og:image" content="%PUBLIC_URL%/images/logo.png" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yaiglobal.com/" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://yaiglobal.com/" />
      </Helmet>

      <SectionContainer className="bg-gradient-to-b from-white to-gray-50">
        <SectionHeader
          title="Our Services"
        />
        <motion.div
          variants={container}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          className="flex flex-col gap-8"
        >
          {/* First Row: 3 Services */}
          <div className="grid gap-8 md:grid-cols-3">
            {services.slice(0, 3).map((service) => {
              const Icon = service.icon;
              return (
                <motion.div
                  key={service.title}
                  variants={item}
                  className="group relative overflow-hidden rounded-lg bg-white p-8 shadow-lg transition-all hover:-translate-y-1 hover:shadow-xl"
                >
                  <div className="relative z-10">
                    <div className="mb-4 inline-flex rounded-xl bg-gradient-to-r from-[#ce04f6] to-[#e0ac48] p-3 text-white">
                      <Icon className="h-8 w-8" />
                    </div>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">{service.title}</h3>
                    <p className="text-gray-600">{service.description}</p>
                  </div>
                  <div
                    className="absolute -right-4 -top-4 h-32 w-32 rounded-full transition-all group-hover:scale-150"
                    style={{
                      background: `linear-gradient(45deg, ${colors.accent1}, ${colors.accent2})`,
                      opacity: 0.15,
                    }}
                  />
                </motion.div>
              );
            })}
          </div>

          {/* Second Row: 3 Services */}
          <div className="grid gap-8 md:grid-cols-3">
            {services.slice(3).map((service) => {
              const Icon = service.icon;
              return (
                <motion.div
                  key={service.title}
                  variants={item}
                  className="group relative overflow-hidden rounded-lg bg-white p-8 shadow-lg transition-all hover:-translate-y-1 hover:shadow-xl"
                >
                  <div className="relative z-10">
                    <div className="mb-4 inline-flex rounded-xl bg-gradient-to-r from-[#ce04f6] to-[#e0ac48] p-3 text-white">
                      <Icon className="h-8 w-8" />
                    </div>
                    <h3 className="mb-3 text-xl font-semibold text-gray-900">{service.title}</h3>
                    <p className="text-gray-600">{service.description}</p>
                  </div>
                  <div
                    className="absolute -right-4 -top-4 h-32 w-32 rounded-full transition-all group-hover:scale-150"
                    style={{
                      background: `linear-gradient(45deg, ${colors.accent1}, ${colors.accent2})`,
                      opacity: 0.15,
                    }}
                  />
                </motion.div>
              );
            })}
          </div>
        </motion.div>
      </SectionContainer>
    </>
  );
}

export default Services;
