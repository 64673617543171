import React from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import {Edit3, Scan, Layout, Mic} from "lucide-react";
import SectionContainer from "../../components/ui/SectionContainer"
import SectionHeader from "../../components/ui/SectionHeader"
import Yai365_editor from "./images/Yai365_editor.png"
import Yai365_ObjectDetection from "./images/Yai365_ObjectDetection.png"
import Yai365_projects from "./images/Yai365_projects.png"
import { useNavigate } from "react-router-dom"; 


const features = [
  {
    icon: Edit3,
    title: "Multi-Data Type Support",
    description: "Supports annotation for images, audio, video, text, and PDFs, ensuring versatility across diverse projects.",
  },
  {
    icon: Scan,
    title: "Advanced AI Tools",
    description: "Leverages powerful AI algorithms for precise annotation and intelligent object detection, enhancing model accuracy.",
  },
  {
    icon: Layout,
    title: "Intuitive Workflow Management",
    description: "Provides an easy-to-use interface with structured workflows, enabling efficient collaboration and seamless project handling.",
  },
  {
    icon: Mic,
    title: "Advanced transcription",
    description: "Yai365's transcription feature converts speech to text, making audio content easily searchable and manageable.",
  }
];

const showcaseItems = [
  {
    image: Yai365_editor,
    title: "Annotation Excellence",
    description: "Yai365 Workbench is a cutting-edge annotation platform trusted by leading organizations to deliver high-quality, expert-driven data labeling solutions. It ensures precise and efficient annotation across multiple data types, including images, audio, video, text, and PDFs. With its intuitive workflow management and advanced AI tools, Yai365 Workbench enables organizations to build robust AI models with unparalleled accuracy, driving innovation and success.",
  },
  {
    image: Yai365_ObjectDetection,
    title: "AI-Powered Object Detection",
    description: "Yai365 Workbench leverages advanced AI tools to enhance object detection capabilities. Its powerful algorithms extract key information from complex data, making it easier to analyze and understand. This intelligent object detection not only improves searchability and content discovery but also empowers organizations to gain deeper insights and make informed decisions with confidence.",
  },
  {
    image: Yai365_projects,
    title: "Diverse Projects & Intuitive User Interface",
    description: "Designed to handle projects of all sizes across diverse industries, Yai365 Workbench caters to both small-scale tasks and enterprise-level initiatives. Its user-friendly interface makes it easy for researchers, data scientists, and project managers to navigate vast digital collections and collaborate efficiently. The platform's structured workflow and seamless integration ensure a productive and engaging annotation experience for users of all expertise levels.",
  },
];

const Yai365Page = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Helmet SEO Metadata */}
      <Helmet>
        <title>YAi365 Workbench - AI-Powered Annotation & Object Detection</title>
        <meta
          name="description"
          content="YAi365 Workbench is an AI-driven annotation platform for labeling images, audio, video, text, and PDFs. Enhance AI model training with powerful annotation and object detection tools."
        />
        <meta property="og:title" content="YAi365 Workbench - AI-Powered Annotation & Object Detection" />
        <meta
          property="og:description"
          content="Boost AI model accuracy with YAi365's intelligent annotation and object detection tools for images, text, and multimedia data."
        />
        <meta property="og:image" content="%PUBLIC_URL%/images/Yai365_ObjectDetection.png" />
        <meta property="og:url" content="https://yaiglobal.com/products/yai365" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://yaiglobal.com/products/yai365" />
      </Helmet>

      <main className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <SectionContainer>
          {/* Section Header */}
          <SectionHeader
          title="Yai365: Precise Annotation for Every Data Type "
          />

          {/* Intro Text */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mb-12 text-lg text-gray-700 leading-relaxed"
          >
            <p className="mb-4">
              Yai365  is an AI-powered annotation platform designed for high-accuracy data labeling.
              It supports:
            </p>
            <ul className="list-disc ml-6">
              <li>Annotation for images, audio, video, text, and PDFs.</li>
              <li>AI-powered object detection for enhanced data insights.</li>
              <li>Seamless collaboration & workflow management.</li>
            </ul>
          </motion.div>

          {/* Features Grid */}
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 mb-16">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white p-6 rounded-lg shadow-md"
              >
                <feature.icon className="h-12 w-12 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>

          {/* Showcase Items */}
          <div className="space-y-24">
            {showcaseItems.map((item, index) => (
              <motion.div
                key={item.title}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className={`flex flex-col items-center gap-8 md:flex-row ${
                  index % 2 === 0 ? "" : "md:flex-row-reverse"
                }`}
              >
                <div className="w-full md:w-1/2">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="rounded-lg shadow-lg object-cover w-full h-88"
                  />
                </div>
                <div className="w-full md:w-1/2 space-y-4">
                  <h3 className="text-2xl font-bold text-gray-900">{item.title}</h3>
                  <p className="text-lg text-gray-600">{item.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
                    <div className="flex justify-center mt-16">
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => navigate("/contact")}
                        className=" px-6 py-3 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
                      >
                        Book a demo
                      </motion.button>
                    </div>
        </SectionContainer>
      </main>
    </>
  );
};

export default Yai365Page;
