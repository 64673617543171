import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import { BookOpen, Globe, Headphones, Search, Bookmark, Edit3, FileText, BarChart, Clipboard, Archive } from "lucide-react";
import SectionContainer from "../../components/ui/SectionContainer";
import SectionHeader from "../../components/ui/SectionHeader";
import { Button } from "../../components/ui/Button";
import Yai4Edu_pipeline from "./images/Yai4Edu_pipeline.png";
import yai4eduEeditor from "./images/yai4eduEeditor.png";
import Yai4Edu_digital_database from "./images/Yai4Edu_digital_database.png";
import videoPoster from "./images/videoPoster.png"

const features = [
  { icon: BookOpen, title: "Advanced OCR", description: "Advanced structured and non-structured content extraction" },
  { icon: FileText, title: "Arabic text processing", description: "Arabic printed and handwritten text processing." },
  { icon: Archive, title: "Ottoman text processing", description: "Ottoman manuscript digitization projects." },
  { icon: Globe, title: "Multilingual Translation", description: "Seamless cross-language communication." },
  { icon: Clipboard, title: "Document Summarization", description: "Intelligent insights from complex documents." },
  { icon: BarChart, title: "Special content extraction", description: "Tables, graphs, charts and more." },
  { icon: Headphones, title: "Text-to-Speech", description: "Transform digitized documents into clear audio." },
  { icon: Search, title: "Full-Text Search", description: "Instant, intelligent content discovery." },
  { icon: Bookmark, title: "Smart Bookmarking", description: "Effortless content organization." },
  { icon: Edit3, title: "Diacritization of Arabic text (تشكيل)", description: "Automatic diacritization for better understanding and pronunciation." },
];

const showcaseItems = [
  {
    image: Yai4Edu_pipeline,
    title: "Effortless Digitization",
    description:
      "Yai4edu offers a seamless digitization experience, allowing users to customize their workflow with AI-driven services. From OCR to translation, summarization, and smart bookmarking, it ensures efficiency and accessibility in document processing.",
  },
  {
    image: yai4eduEeditor,
    title: "Human in the loop editing ",
    description:
      "The Yai4Edu editor provides users with a smart interface to refine and validate digitized content, including OCR-extracted text, translations, and diacritized Arabic text, ensuring accuracy and quality.",
  },
  {
    image: Yai4Edu_digital_database,
    title: "Intuitive Navigation",
    description:
      "Yai4edu offers an intuitive interface for easy document organization, search, and retrieval, with structured access control for Institutions and Individuals.",
  },
];

const yai4eduDemoLink = "https://storage.googleapis.com/yai365.com/yai4edu_demo/3bea1b9f-9057-4d93-99e0-df049bcdd2cf/demo_yai4edu.mp4"

const YAi4EduPage = () => {
  return (
    <>
      {/* Helmet SEO Metadata */}
      <Helmet>
        <title>Yai4Edu - AI-Powered Library Digitization</title>
        <meta name="description" content="Discover YAi4Edu, an advanced AI-driven library digitization platform offering OCR, translation, summarization, and full-text search." />
        <meta property="og:title" content="YAi4Edu - AI-Powered Library Digitization" />
        <meta property="og:description" content="Streamline your document processing with YAi4Edu's AI-powered OCR, translation, summarization, and search capabilities." />
        <meta property="og:image" content="%PUBLIC_URL%/images/yai4eduEeditor.png" />
        <meta property="og:url" content="https://yaiglobal.com/products/yai4edu" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://yaiglobal.com/products/yai4edu" />
      </Helmet>

      <main className="bg-white">
        <SectionContainer>
          {/* Hero Section */}
          <div className="text-center py-2 mb-2 ">
            <SectionHeader title="Yai4Edu: Reimagining Knowledge" subtitle="AI-Driven Library Transformation" />
            <p className="max-w-4xl mx-auto text-gray-600 mt-[-40px]">
            Yai4Edu is an advanced AI-powered digitization service designed to transform how libraries manage and share collections. It includes document summarization, multilingual translation, full-text search, bookmarking, Text To Speech (TTS), and more. 
            </p>
          </div>
            <div className="text-center py-2">
              <Button
                size="lg"
                className="bg-blue-600 hover:bg-blue-700 text-white text-lg px-8 py-3"
                onClick={() => window.open("https://yai4edu.yai365.com/", "_blank")}
              >
                Try Now
              </Button>
            </div>
          <div className="text-center py-1">
              <p className="text-md text-gray-600 mb-4"><b>Watch</b> how Yai4Edu processes and organizes documents</p>
            </div>
            
            <div className="relative mx-auto aspect-video w-full max-w-xl overflow-hidden rounded-lg shadow-xl mb-12">

              <video
                controls
                className="h-full w-full"
                poster={videoPoster}
              >
                <source src={yai4eduDemoLink} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          {/* Features Grid */}
          <div className="grid md:grid-cols-5 gap-8 py-16">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="text-center space-y-4 p-6 rounded-xl hover:bg-gray-50 transition-colors group"
              >
                <feature.icon className="mx-auto h-12 w-12 text-blue-600 group-hover:text-blue-700 transition-colors" />
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">{feature.title}</h3>
                  <p className="text-md text-gray-500 mt-2">{feature.description}</p>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Showcase Section */}
          <div className="space-y-24 py-16">
            {showcaseItems.map((item, index) => (
              <motion.div
                key={item.title}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className={`flex flex-col md:flex-row items-center gap-12 ${
                  index % 2 === 1 ? "md:flex-row-reverse" : ""
                }`}
              >
                <div className="md:w-1/2">
                  <motion.img
                    src={item.image}
                    alt={item.title}
                    className="w-full rounded-2xl shadow-xl"
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                  />
                </div>
                <div className="md:w-1/2 space-y-4 text-center md:text-left">
                  <h2 className="text-3xl font-bold text-gray-900">{item.title}</h2>
                  <p className="text-gray-600">{item.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </SectionContainer>
      </main>
    </>
  );
};

export default YAi4EduPage;
