import React from 'react';
import Navbar from '../components/Navbar';
import Carousel from "../components/Carousel";
import Services from "../components/Services";
import About from "../components/About";
import CTA from "../components/CTA";
import Products from '../components/Products';
import Contact  from '../components/Contact';
import Partners from '../components/Partners'

export default function HomePage() {


  return (
    <main className="overflow-hidden">
      <Navbar />

      <div id="carousel" className="pt-20 scroll-mt-20 bg-gray-900 text-white"  data-bg="dark" >
        <Carousel />
      </div>
      <div id="services" className="pt-20 scroll-mt-20">
        <Services />
      </div>
      <div id="about" className="pt-20 scroll-mt-20 ">
        <About />
      </div>
      <div id="products" className="pt-20 scroll-mt-20">
        <Products />
      </div>
      <div id="contact" className="pt-20 scroll-mt-20">
        <Contact />
      </div>

    </main>
  )
}
