import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import HomePage from "./pages/HomePage";
import YAi4EduPage from "./products/yai4edu/page";
import Yai4healthPage from "./products/yai4health/page";
import Yai365Page from "./products/yai365/page";
import Contact from "./components/Contact";


const App = () => {
  return (
    <HelmetProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products/yai4edu" element={<YAi4EduPage />} />
          <Route path="/products/yai365" element={<Yai365Page />} />
          <Route path="/products/yai4health" element={<Yai4healthPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </HelmetProvider>
  );
};

export default App;
