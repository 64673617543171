import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "./ui/Button";
import { Menu, X, ChevronDown } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../images/logo.png";

const menuItems = ["services", "about", "products", "contact"];

export function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [isProductsOpen, setIsProductsOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();


  const isProductPage = location.pathname.startsWith("/products/") || location.pathname.startsWith("/contact") ;

  const isHomePage = location.pathname === "/";

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
            const sections = document.querySelectorAll("[data-section]");
      let currentSection = "";
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 100 && rect.bottom >= 100) {
          currentSection = section.id;
        }
      });
      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleSmoothScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setActiveSection(id);
    }
  };

  const handleNavClick = (item) => {
    if (item === "products") {
      return;
    }
    if (isProductPage) {
      navigate(`/#${item}`);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);
    } else {
      handleSmoothScroll(item);
    }
    setIsOpen(false);
    setIsProductsOpen(false);
  };

  const handleLogoClick = () => {
    if (isProductPage) {
      navigate("/");
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);
    } else {
      handleSmoothScroll("carousel");
    }
  };


  const goToProductPage = (productPath) => {
    navigate(productPath);
    setIsProductsOpen(false);
    setIsOpen(false);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  };

  return (
    <motion.nav
      initial={{ y: isHomePage ? 0 : -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.3 }}
      className={`fixed top-0 z-50 w-full transition-all duration-300 ${
        (isProductPage || isScrolled)
          ? "bg-white/80 backdrop-blur-lg shadow-sm text-gray-900"
          : "bg-transparent text-white"
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex h-20 items-center justify-between">
          {/* Logo */}
          <a onClick={handleLogoClick} className="flex items-center cursor-pointer">
            <img src={logo} alt="YAI Global Logo" className="h-12" />
          </a>

          {/* Desktop Menu */}
          <div className="hidden space-x-8 md:flex items-center">
            {menuItems.map((item) => {
              if (item === "products") {
                return (
                  <div key={item} className="relative">
                    <button
                      onClick={() => setIsProductsOpen(!isProductsOpen)}
                      className="flex items-center cursor-pointer transition-colors hover:text-gray-500"
                    >
                      Products <ChevronDown className="h-4 w-4 ml-1" />
                    </button>
                    <AnimatePresence>
                      {isProductsOpen && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="absolute left-0 mt-2 w-48 bg-white text-gray-900 shadow-lg rounded-md overflow-hidden"
                        >
                          <a
                            onClick={() => goToProductPage("/products/yai4edu")}
                            className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          >
                            Yai4Edu
                          </a>
                          <a
                            onClick={() => goToProductPage("/products/yai365")}
                            className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          >
                            Yai365
                          </a>
                          <a
                            onClick={() => goToProductPage("/products/yai4health")}
                            className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          >
                            Yai4Health
                          </a>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                );
              } else {
                return (
                  <a
                    key={item}
                    onClick={() => handleNavClick(item)}
                    className={`cursor-pointer transition-colors hover:text-gray-500 ${
                      activeSection === item ? "text-teal-500 font-semibold" : ""
                    }`}
                  >
                    {item.charAt(0).toUpperCase() + item.slice(1)}
                  </a>
                );
              }
            })}
          </div>

          {/* Mobile Menu Toggle */}
          <div className="md:hidden">
            <Button variant="ghost" size="icon" onClick={() => setIsOpen(!isOpen)} className="text-inherit">
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </Button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className="bg-white text-gray-900 border-t md:hidden"
          >
            <div className="container mx-auto space-y-1 p-4">
              {menuItems.map((item) => {
                if (item === "products") {
                  // mobile products menu 
                  return (
                    <div key={item}>
                      <button
                        onClick={() => setIsProductsOpen(!isProductsOpen)}
                        className="flex w-full items-center justify-between px-3 py-2 font-medium transition-colors hover:bg-gray-100"
                      >
                        Products <ChevronDown className="h-4 w-4" />
                      </button>
                      <AnimatePresence>
                        {isProductsOpen && (
                          <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            className="mt-1 bg-white rounded-md shadow-lg overflow-hidden"
                          >
                            <a
                              onClick={() => goToProductPage("/products/yai4edu")}
                              className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            >
                              Yai4Edu
                            </a>
                            <a
                              onClick={() => goToProductPage("/products/yai365")}
                              className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            >
                              Yai365 Workbench
                            </a>
                            <a
                              onClick={() => goToProductPage("/products/yai4health")}
                              className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            >
                              Yai4Health
                            </a>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  );
                } else {
                  return (
                    <a
                      key={item}
                      onClick={() => handleNavClick(item)}
                      className={`block rounded-md px-3 py-2 text-base font-medium transition-colors hover:bg-gray-100 ${
                        activeSection === item ? "text-teal-500 font-semibold" : ""
                      }`}
                    >
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </a>
                  );
                }
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
}

export default Navbar;
