import React from "react";
import { motion } from "framer-motion";
import { Phone, Mail, MapPin } from "lucide-react";
import SectionContainer from "./ui/SectionContainer";
import { colors } from "../styles/colors";

export default function Contact() {
  return (
    <SectionContainer className="bg-gray-50 text-gray-900 py-20">
      <div className="container mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="flex flex-col md:flex-row justify-between items-center max-w-7xl mx-auto gap-16 md:gap-24"
        >
          <div className="w-full md:w-1/3 text-left">
            <h2 className="text-5xl md:text-6xl font-bold leading-tight">
              <span style={{ color: colors.primary }}>Contact</span> <br /> Us
            </h2>
          </div>

          <div className="w-full md:w-2/3 space-y-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="flex items-center gap-6 bg-gray-100 p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300 border border-gray-200"
            >
              <div className="w-16 h-16 flex items-center justify-center bg-gradient-to-r from-[#ce04f6] to-[#e0ac48] rounded-full shadow-md">
                <Phone className="text-white w-8 h-8" />
              </div>
              <div className="text-xl font-medium text-gray-800">+1 (319) 777-4015</div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="flex items-center gap-6 bg-gray-100 p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300 border border-gray-200"
            >
              <div className="w-16 h-16 flex items-center justify-center bg-gradient-to-r from-[#ce04f6] to-[#e0ac48] rounded-full shadow-md">
                <Mail className="text-white w-8 h-8" />
              </div>
              <div className="text-xl font-medium text-gray-800">info@yaiglobal.com</div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="flex items-center gap-6 bg-gray-100 p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300 border border-gray-200"
            >
              <div className="w-16 h-16 flex items-center justify-center bg-gradient-to-r from-[#ce04f6] to-[#e0ac48] rounded-full shadow-md">
                <MapPin className="text-white w-8 h-8" />
              </div>
              <div className="text-xl font-medium text-gray-800 leading-relaxed">
                5201 Great America Pkwy, Santa Clara, CA 95054, USA
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </SectionContainer>
  );
}
