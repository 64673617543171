// src/styles/colors.js
export const colors = {
    primary: "#ce04f6",
    secondary: "#e0ac48",
    background: "#ffffff",
    text: "#1a202c",
    textLight: "#4a5568",
    accent1: "#f7d6ff", // Light purple
    accent2: "#fff2d6", // Light gold
    gray: {
      50: "#f7fafc",
      100: "#edf2f7",
      200: "#e2e8f0",
      300: "#cbd5e0",
      400: "#a0aec0",
      500: "#718096",
      600: "#4a5568",
      700: "#2d3748",
      800: "#1a202c",
      900: "#171923",
    },
  }
  