import React from "react"
import PropTypes from "prop-types";

export function SectionContainer({ children, className = "" }) {
  return (
    <section className={`py-20 md:py-28 ${className}`}>
      <div className="container mx-auto px-4 md:px-8">{children}</div>
    </section>
  )
}

export default SectionContainer;

SectionContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};