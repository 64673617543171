// src/components/Footer.js
import React from "react"
import { motion } from "framer-motion"
import { useNavigate, useLocation } from "react-router-dom" 
import { colors } from "../styles/colors"

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.1 }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};


const linksMap = {
  "OCR": "services",
  "Translation": "services",
  "Summarization": "services",
  "PDF Bookmarking": "services",
  "About Us": "about",
  "Contact": "contact"
};

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  const isProductPage = location.pathname.startsWith("/products/");

  const smoothScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };


  const handleFooterLinkClick = (label) => {
    const sectionId = linksMap[label];
    if (!sectionId) return;

    if (isProductPage) {
      navigate(`/#${sectionId}`);
      setTimeout(() => window.scrollTo(0, 0), 50);
    } else {
      smoothScrollToSection(sectionId);
    }
  };

  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-12">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid gap-8 md:grid-cols-4"
        >
          <motion.div variants={itemVariants}>
            <h3 className="mb-4 text-2xl font-bold" style={{ color: colors.secondary }}>
              YaiGlobal
            </h3>
            <p className="text-gray-300">
              Advanced NLP solutions for document processing and insights.
            </p>
          </motion.div>

          <motion.div variants={itemVariants}>
            <h4 className="mb-4 font-semibold" style={{ color: colors.secondary }}>
              Services
            </h4>
            <ul className="space-y-2">
              {["OCR", "Translation", "Summarization", "PDF Bookmarking"].map((item) => (
                <motion.li
                  key={item}
                  whileHover={{ x: 5 }}
                  transition={{ duration: 0.2 }}
                >
                  <button
                    onClick={() => handleFooterLinkClick(item)}
                    className="text-gray-300 transition-colors hover:text-white cursor-pointer"
                  >
                    {item}
                  </button>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          <motion.div variants={itemVariants}>
            <h4 className="mb-4 font-semibold" style={{ color: colors.secondary }}>
              Company
            </h4>
            <ul className="space-y-2">
              {["About Us", "Contact"].map((item) => (
                <motion.li
                  key={item}
                  whileHover={{ x: 5 }}
                  transition={{ duration: 0.2 }}
                >
                  <button
                    onClick={() => handleFooterLinkClick(item)}
                    className="text-gray-300 transition-colors hover:text-white cursor-pointer"
                  >
                    {item}
                  </button>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          <motion.div variants={itemVariants}>
            <h4 className="mb-4 " style={{ color: colors.secondary }}>
              Connect
            </h4>
            <div className="flex space-x-4">
            info@yaiglobal.com
            </div>
          </motion.div>
        </motion.div>

        <motion.div
          variants={itemVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="mt-8 border-t border-gray-800 pt-8 text-center text-gray-400"
        >
          <p>&copy; 2025 YaiGlobal. All rights reserved.</p>
        </motion.div>
      </div>
    </footer>
  );
}
